

.Home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
}


.vehicle-icons {
    width: 100%;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    opacity: 0.3;
}

.vehicle-icons img {
    width: 15%;
    max-width: 190px;
    object-fit: contain;
    aspect-ratio: 3/2;
}
.vehicle-icons-inner-div-top {
    display: flex;
    margin: 0;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 14vh;
}

.vehicle-icons-inner-div-bottom {
    display: flex;
    margin: 0;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 3rem 0 10px 0;
}

.locations {
    text-align: center;
    padding: 10px;
}

.logo-div {
    margin: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 6.5rem 0 0 0;
    background: rgb(255, 255, 255);
    color: rgb(31, 31, 31);
}

.logo-div p {
    color: rgb(45, 45, 45);
    font-size: x-large;
    margin: 0;
    padding: 0rem 2rem 0 2rem;
}

.company-logo {
    margin: 0;
    height: 50%;
    width: 90%;
    max-width: 1000px;
    object-fit: contain;
}

.reviews-div {
    margin: 1rem 0 1rem 0;
    
}

.reviews-div button {
    padding: 15px 20px;
    border-radius: 30px;
    background-color: rgb(246, 36, 36);
    font-size: large;
    color: rgb(255, 255, 255);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    border: none;
}

.reviews-div button p {
    font-size: small;
    font-family: 'Times New Roman', Times, serif;
    color: white;
}

.reviews-div button a:link, .reviews-div button a:visited {
    color: rgb(255, 255, 255);
    
}



@media only screen and (max-width: 700px) {
    .logo-div p {
        font-size: medium;
    }
    .locations {
        font-size: small;
    }
}

@media only screen and (max-width: 600px) {
    .reviews-div button {
        font-size: medium;
        padding: 20px 30px;
    }
    .reviews-div button p {
        font-size: xx-small;
    }

}


